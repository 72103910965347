import React, { ReactNode } from "react";
import { Typography } from "@design-system-rt/rtk-ui-kit";
import styled from "styled-components";
import { PagesConfigType } from "../types/config";
import { MEDIA } from "./media";

const StyledTypography = styled(Typography)`
  white-space: nowrap;

  @media screen and ${MEDIA.toTablet} {
    margin-top: 24px;
  }
`;
const StyledVersion = styled.span`
  padding: 2px 4px;
  background: rgba(16, 24, 40, 0.05);
  margin-left: 9px;
`;

const PagesConfig: PagesConfigType = [
  {
    generation: ["gen1", "gen2"],
    name: "О Дизайн-системе",
    id: "designsystem",
    path: "/designsystem",
    subsectionsInfo: [
      {
        name: "",
        id: "designsystemSubsection",
        pagesInfo: [
          {
            generation: ["gen1", "gen2"],
            id: "gettingStarted",
            path: "/gettingStarted",
            name: "Начало работы",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/documentation-introduction--page",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/welcome-introduction--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/welcome-introduction--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/files/1189203705416926780/project/77615034/%D0%94%D0%B8%D0%B7%D0%B0%D0%B9%D0%BD-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0-%D0%A0%D0%BE%D1%81%D1%82%D0%B5%D0%BB%D0%B5%D0%BA%D0%BE%D0%BC-%D0%90%D1%82%D0%BE%D0%BC%D0%B0%D1%80%D0%BE?fuid=1190223695741581429",
              gen2:
                "https://www.figma.com/files/1189203705416926780/project/171468544/%D0%94%D0%B8%D0%B7%D0%B0%D0%B9%D0%BD-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0-%D0%A0%D0%BE%D1%81%D1%82%D0%B5%D0%BB%D0%B5%D0%BA%D0%BE%D0%BC-%D0%90%D1%82%D0%BE%D0%BC%D0%B0%D1%80%D0%BE-NEW?fuid=1190223695741581429",
            },
            tabs: [
              {
                key: "intro",
                label: "Введение",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    generation: ["gen1", "gen2"],
    name: "Основы и стиль",
    id: "basics",
    path: "/basics",
    subsectionsInfo: [
      {
        name: "",
        id: "basicsSubsection",
        pagesInfo: [
          {
            generation: ["gen2"],
            id: "theme",
            path: "/theme",
            name: "Темизация",
            active: true,
            tabs: [
              {
                key: "intro",
                label: "Введение",
              },
              {
                key: "custom",
                label: "Кастомизация",
              },
              {
                key: "new",
                label: "Новая тема",
              },
            ],
          },
          {
            generation: ["gen2"],
            id: "designTokens",
            path: "/designTokens",
            name: "Дизайн-токены",
            active: true,
            tabs: [
              {
                key: "about",
                label: "О дизайн-токенах",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "colors",
            path: "/colors",
            name: "Цвета",
            active: true,
            figmaLink: {
              gen1:
                "https://www.figma.com/file/uhVgRo49kxsPiUkTJzx1Vv/Atomaro-Style?node-id=0%3A1",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=909%3A23292&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "palette",
                label: "Палитра",
              },
              {
                key: "anatomy",
                label: "Анатомия цветов",
                gen: ["gen2"],
              },
              {
                key: "schemes",
                label: "Цветовые схемы",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "typography",
            path: "/typography",
            name: "Типографика",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-typography--typography",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-typography--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-typography--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=155%3A0",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=1132%3A30816&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "preview",
                label: "Превью",
              },
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1"],
            id: "grid",
            path: "/grid",
            name: "Сетка и отступы",
            active: true,
            tabs: [
              {
                key: "module",
                label: "Модуль и сетка",
              },
              {
                key: "layout",
                label: "Разметка",
              },
              {
                key: "spacings",
                label: "Система отступов",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "icons",
            path: "/icons",
            name: "Иконки",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-icons-resizable--actions",
              gen2: undefined,
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/54csM4ZUXjkteLC7LZXiow/Atomaro-Icons?node-id=30%3A2",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=1784%3A12910&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "preview",
                label: "Превью",
              },
              {
                key: "create",
                label: "Гайд по проектированию",
              },
              {
                key: "publish",
                label: "Правила публикации",
              },
            ],
          },
          {
            generation: ["gen2"],
            id: "animation",
            path: "/animation",
            name: "Анимация",
            active: true,
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen2"],
            id: "variants",
            path: "/variants",
            name: "Варианты и размеры",
            active: true,
            tabs: [
              {
                key: "intro",
                label: "Интро",
              },
              {
                key: "variant",
                label: "Variant",
              },
              {
                key: "size",
                label: "Size",
              },
              {
                key: "colorscheme",
                label: "ColorScheme",
              },
            ],
          },
          {
            generation: ["gen2"],
            id: "spacing",
            path: "/spacing",
            name: "Отступы",
            active: true,
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "interface",
            path: "/interface",
            name: "Уровни интерфейса",
            active: true,
            tabs: [
              {
                key: "intro",
                label: "Интро",
              },
              {
                key: "shadows",
                label: "Уровни",
              },
              {
                key: "interaction",
                label: "Взаимодействие",
              },
              {
                key: "overlay",
                label: "Затемняющий слой",
              },
              {
                key: "hierarchy",
                label: "Визуальная иерархия",
              },
            ],
          },
          {
            generation: ["gen2"],
            id: "shadows",
            path: "/shadows",
            name: "Тени",
            active: true,
            tabs: [
              {
                key: "preview",
                label: "Превью",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "validation",
            path: "/validation",
            name: "Валидация",
            active: true,
            tabs: [
              {
                key: "principles",
                label: "Принципы валидации",
              },
              {
                key: "fields",
                label: "Валидация полей",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "designdev",
            path: "/designdev",
            name: "Рекомендации дизайнерам",
            active: true,
            tabs: [
              {
                key: "guide",
                label: "Подготовка макетов к разработке",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    generation: ["gen1", "gen2"],
    name: "Компоненты",
    id: "components",
    path: "/components",
    subsectionsInfo: [
      {
        name: "Кнопки",
        id: "buttons",
        pagesInfo: [
          {
            generation: ["gen1", "gen2"],
            id: "btn",
            path: "/btn",
            name: "Button",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-buttons-button--button",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-buttons-button--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-buttons-button--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17093%3A27065",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=0%3A1&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
              {
                key: "btn-text",
                label: "Текст на кнопке",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "floating_action_button",
            path: "/floating_action_button",
            name: "FloatingActionButton",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-floatingactionbutton--floating-action-button",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-floatingactionbutton--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-floatingactionbutton--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17093%3A288281",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=635%3A397&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            id: "function_button",
            generation: ["gen1", "gen2"],
            path: "/function_button",
            name: "FunctionButton",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-buttons-function-button--function-button",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-buttons-functionbutton--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-buttons-functionbutton--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17093%3A288282",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=125%3A9096&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "icon_button",
            path: "/icon_button",
            name: "IconButton",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-buttons-icon-button--icon-button",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-buttons-iconbutton--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-buttons-iconbutton--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17093%3A317878",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=101%3A1700&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
        ],
      },
      {
        name: "Навигация",
        id: "navigation",
        pagesInfo: [
          {
            generation: ["gen1", "gen2"],
            id: "breadcrumbs",
            path: "/breadcrumbs",
            name: "Breadcrumbs",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-breadcrumbs--breadcrumbs",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-breadcrumbs--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-breadcrumbs--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A31114",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=631%3A188004&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1"],
            id: "sticker",
            path: "/sticker",
            name: "Sticker",
            active: true,
            storybookLink:
              "https://design.rt.ru/storybook/?path=/story/components-sticker--sticker",
            figmaLink:
              "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A31182",
            gitlabLink:
              "https://git.digital.rt.ru/tech.support/design-system-rt",
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "tabs_classic",
            path: "/tabs_classic",
            name: "Tabs",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/docs/components-tabs--tabs",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-tabs-tabs--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-tabs-tabs--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A32981",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=141%3A7623&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
        ],
      },
      {
        name: "Поля ввода",
        id: "inputs",
        pagesInfo: [
          {
            generation: ["gen1", "gen2"],
            id: "input",
            path: "/input",
            name: "Input",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-input--input",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-input--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-input--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A33589",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=118%3A2249&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "input_amount",
            path: "/input_amount",
            name: "InputAmount",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-inputamount--input-amount",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-input--docs#input-amount",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/story/components-input--input-amount",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A33589",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=118%3A2249&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "input_card",
            path: "/input_card",
            name: "InputCard",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-inputcard--input-card",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-inputcard--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-inputcard--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A34309",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=138%3A6877&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "input_date",
            path: "/input_date",
            name: "InputDate",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-inputdate--input-date",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-inputdate--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-inputdate--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A34629",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=318%3A22549&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "input_email",
            path: "/input_email",
            name: "InputEmail",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-inputemail--input-email",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/story/components-input--input-email",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/story/components-input--input-email",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A33589",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=118%3A2249&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "input_number_stepper",
            path: "/input_number_stepper",
            name: "InputNumberStepper",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-inputnumberstepper--input-number-stepper",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-inputnumberstepper--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-inputnumberstepper--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A37442",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=400%3A87357&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "input_phone_number",
            path: "/input_phone_number",
            name: "InputPhoneNumber",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-inputphone--input-phone",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/story/components-input--input-phone",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-input--docs#input%20phone",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A33589",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=118%3A2249&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "multiselect",
            path: "/multiselect",
            name: "MultiSelect",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-multiselect--multi-select",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-multiselect--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-multiselect--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A38319",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=355%3A44010&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "select",
            path: "/select",
            name: "Select",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-select--select",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-select--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-select--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A40656",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=216%3A13181&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "textarea",
            path: "/textarea",
            name: "TextArea",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-textarea--text-area",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-textarea--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-textarea--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A41622",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=161%3A10925&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
        ],
      },
      {
        name: "Контролы",
        id: "controllers",
        pagesInfo: [
          {
            generation: ["gen1", "gen2"],
            id: "checkbox",
            path: "/checkbox",
            name: "Checkbox",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-checkbox--checkbox",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-checkboxes-checkbox--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-checkboxes-checkbox--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A49183",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=45%3A2775&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "chips",
            path: "/chips",
            name: "Chips",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-chip--chip",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-chips-chipgroup--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-chipgroup--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17111%3A39819",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=72%3A2661&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "radio_button",
            path: "/radio_button",
            name: "RadioButton",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-radiobutton--radio-button",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-radiobuttons-radiobutton--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-radiobuttons-radiobutton--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17111%3A39900",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=113%3A2139&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "scroll",
            path: "/scroll",
            name: "Scroll",
            active: true,
            storybookLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/tools-scrollbar--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/tools-scrollbar--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=19641%3A290224",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=118%3A2143&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "segmented_control",
            path: "/segmented_control",
            name: "SegmentedControl",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-segmentedcontrol--segmented-control",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-segmentedcontrol-segmentedcontrol--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-segmentedcontrol-segmentedcontrol--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A31226",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=504%3A76922&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "slider",
            path: "/slider",
            name: "Slider",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/docs/components-slider--slider",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-slider--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-slider--docs",
            },
            figmaLink: {
              gen1: undefined,
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=644%3A70917&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "stepper",
            path: "/stepper",
            name: "Stepper",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-stepper--stepper",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-stepper--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-stepper--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17111%3A40654",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=459%3A51251&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "switch",
            path: "/switch",
            name: "Switch",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-switch--switch",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-switch--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-switch--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17111%3A41145",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=128%3A6778&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
        ],
      },
      {
        name: "Всплывающие слои",
        id: "Popup windows",
        pagesInfo: [
          {
            generation: ["gen1", "gen2"],
            id: "dropdown_menu",
            path: "/dropdown_menu",
            name: "DropdownMenu",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-dropdownmenu--dropdown-menu",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-dropdownmenu--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-dropdownmenu--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A41893",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=157%3A9177&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "picker_date",
            path: "/picker_date",
            name: "PickerDate",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-pickerdate--picker-date",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-pickerdate--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-pickerdate--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A45695",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=674%3A85870&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1"],
            id: "picker_time",
            path: "/picker_time",
            name: "PickerTime",
            active: true,
            storybookLink:
              "https://design.rt.ru/storybook/?path=/story/components-pickertime--picker-time",
            figmaLink:
              "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A45695",
            gitlabLink:
              "https://git.digital.rt.ru/tech.support/design-system-rt",
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen2"],
            id: "overlay",
            path: "/overlay",
            name: "Overlay",
            active: true,
            storybookLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-overlay--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-overlay--docs",
            },
            figmaLink: {
              gen1: undefined,
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=1938%3A158753&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
        ],
      },
      {
        name: "Нотификации и индикаторы",
        id: "NotificationsAndIndicators",
        pagesInfo: [
          {
            generation: ["gen2"],
            id: "badge",
            path: "/badge",
            name: "Badge",
            active: true,
            storybookLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-badge--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-badge--docs",
            },
            figmaLink: {
              gen1: undefined,
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=82%3A1698&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "loader",
            path: "/loader",
            name: "Loader",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-loader--loader",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-loader--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-loader--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17480%3A44543",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=109%3A2212&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "notification_inline",
            path: "/notification_inline",
            name: "Notification Inline",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-notification-inline-notification--inline-notification",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-notification-inline-notification--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-notification-inlinenotification--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A42639",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=616%3A186391&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "notification_toast",
            path: "/notification_toast",
            name: "Notification Toast",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-notification-toast-notification--toast-notifications",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-notification-toast-notification--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-notification-toastnotification--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A42639",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=616%3A187268&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen2"],
            id: "popover",
            path: "/popover",
            name: "Popover",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/docs/components-popover--popover",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-popover--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-popover--docs",
            },
            figmaLink: {
              gen1: undefined,
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=616%3A186204&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "tags",
            path: "/tags",
            name: "Tags",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/docs/components-tag-taggroup--tag-group",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-tag-taggroup--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-tag-taggroup--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17111%3A41146",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=119%3A2818&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "tooltip",
            path: "/tooltip",
            name: "Tooltip",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/storybook/?path=/story/components-tooltip--tooltip",
              gen2:
                "https://design.rt.ru/gen2/react-storybook/?path=/docs/components-tooltip--docs",
            },
            storybookVueLink: {
              gen1: undefined,
              gen2:
                "https://design.rt.ru/gen2/vue-storybook/?path=/docs/components-tooltip--docs",
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/4Q1Ko3R8fQBvUnbLQ5c27d/Atomaro-Web?node-id=17105%3A49015",
              gen2:
                "https://www.figma.com/file/gSAXNMGP4J0kJ6ws0NCWh4/Atomaro-Pro-Rostelecom?type=design&node-id=241%3A18930&mode=design&t=fcPG8apwyb4WUu3M-1",
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
        ],
      },
      {
        name: "Паттерны",
        id: "patterns",
        pagesInfo: [
          {
            generation: ["gen1", "gen2"],
            id: "tree",
            path: "/tree",
            name: "Tree",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/treestorybook/?path=/story/components-tree--example&globals=measureEnabled:false",
              gen2: undefined,
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/TqEAiwJMQ0Ex7AVC4PSWSF/Atomaro-TableGrid-Beta?type=design&node-id=1542-180581&mode=design&t=Rc0lBAscW1WJP8RO-0",
              gen2: undefined,
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
          {
            generation: ["gen1", "gen2"],
            id: "table_grid",
            path: "/table_grid",
            name: "TableGrid",
            active: true,
            storybookLink: {
              gen1:
                "https://design.rt.ru/tablesstorybook/?path=/story/components-tablegrid--table-grid-custom",
              gen2: undefined,
            },
            figmaLink: {
              gen1:
                "https://www.figma.com/file/TqEAiwJMQ0Ex7AVC4PSWSF/Atomaro-TableGrid-Beta?type=design&node-id=6-21129&mode=design&t=Rc0lBAscW1WJP8RO-0",
              gen2: undefined,
            },
            tabs: [
              {
                key: "guide",
                label: "Гайд по использованию",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    generation: [],
    name: "Единый опыт",
    id: "experience",
    path: "/experience",
    subsectionsInfo: [
      {
        name: "",
        id: "ecosystemSubsection",
        pagesInfo: [
          {
            generation: [],
            id: "rtkid",
            path: "/rtkid",
            name: "РТК ID",
            active: true,
            tabs: [
              {
                key: "description",
                label: "Описание",
              },
            ],
          },
          {
            generation: [],
            id: "omnichat",
            path: "/omnichat",
            name: "Омничат",
            active: true,
            tabs: [
              {
                key: "description",
                label: "Описание",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    generation: [],
    name: "История изменений",
    id: "history",
    path: "/history",
    subsectionsInfo: [],
    additionalInfo: (gen: string): ReactNode => (
      <StyledTypography variant="caption" color="description">
        Последняя версия
        <StyledVersion>{gen === "gen1" ? "v7.12.0" : "v1.1.0"} </StyledVersion>
      </StyledTypography>
    ),
    tabs: [
      {
        key: "all",
        label: "Все записи",
      },
      {
        key: "releases",
        label: "Релизы",
      },
      {
        key: "announcements",
        label: "Анонсы",
      },
    ],
  },
  {
    generation: [],
    name: "Инструменты",
    id: "tools_public",
    path: "/tools_public",
    subsectionsInfo: [
      {
        name: "",
        id: "toolsSubsection",
        pagesInfo: [
          {
            generation: [],
            id: "avatarmaker",
            path: "/avatarmaker",
            name: "Avatar Maker",
            active: true,
            tabs: [
              {
                key: "description",
                label: "Описание",
              },
            ],
          },
          {
            generation: [],
            id: "arti",
            path: "/arti",
            name: "ARTi",
            active: true,
            tabs: [
              {
                key: "description",
                label: "Онлайн-доска",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default PagesConfig;
